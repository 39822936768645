import { AssignedLabelDTO } from '@/modules/labels/api/label.contracts';

export interface LocationReadDTO extends LocationCreateDTO {
  id: number;
  parentLocationIdLevel1?: number;
  parentLocationIdLevel2?: number;
  children: LocationReadDTO[];
  isSystem: boolean;
  labels?: AssignedLabelDTO[];

  createdAt: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}

export interface LocationCreateDTO {
  name: string;
  code: string;
  description: string;
  geofence?: number[][] | null;
  country?: string | null | undefined;
  city?: string | null | undefined;
  postalCode?: string | null | undefined;
  streetName?: string | null | undefined;
  streetNumber?: string | null | undefined;
  parentLocationId?: number | null;
}

export interface LocationUpdateDTO extends LocationCreateDTO {}

export interface AssetTotalAtLocationDTO {
  id: number;
  name: string;
  totalAssets: number;
  coordinates: number[];
  inTransitAssetId?: number;
}

export interface BalanceAtLocationDto {
  id: number;
  name: string;
  code: string;
  individualBalance: number;
  hierarchicalBalance: number;
}

export interface LocationByAssetCountDTO {
  id: number;
  name: string;
  code: string;
  assetCount: number;
}

export interface LocationByAssetMonetaryValueDTO {
  id: number;
  name: string;
  code: string;
  totalMonetaryValue: number;
}

export interface LocationAssetDTO {
  assetId: number;
  assetTypeId: number;
  assetTypeName: string;
  assetCode: string;
  dateArrivedAtLocation: Date;
}

export interface AssetTypeBalanceAtLocationDto {
  id?: number;
  name?: string;
  totalBalance: number;
  balances: AssetTypeBalanceAtLocationDetailDto[];
  coordinates?: number[];
  inTransitAssetId?: number;
}

export interface AssetTypeBalanceAtLocationDetailDto {
  id: number;
  name: string;
  balance: number;
}

export enum LocationAssetSortOption {
  ASSET_CODE = 'asset-code',
  ASSET_TYPE_NAME = 'asset-type-name',
  ARRIVED_AT = 'arrived-at',
}
