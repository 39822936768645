import { Box, Drawer, IconButton, Portal, Tab } from '@mui/material';
import { FunctionComponent, SyntheticEvent, useEffect, useState } from 'react';
import { Close, IosShareRounded } from '@mui/icons-material';
import { useApiCall } from '@/hooks/useApiCall';
import LoadingBlock from '@/components/feedback/LoadingBlock';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { locationService } from '@/modules/locations/api/locations/location.service';
import { balanceService } from '../api/balances/balance.service';
import BalanceOverTimeGraph from './BalanceOverTimeGraph';
import { GroupPerTimeType } from '@/modules/dashboards/components/widgets/events-over-time-widget/group-per-time-type.enum';
import TextLink from '@/components/TextLink';
import PeriodToggleButton from './PeriodToggleButton';

import { DisplayMode } from '@/types/display-mode.enum';
import { LocationBalanceDrawerBalanceGrid } from './LocationBalanceDrawerBalanceGrid';
import { AssetsAtLocationGrid } from './AssetsAtLocationGrid';
import { AssetSortOption } from '@/modules/assets/api/assets/asset.contracts';
import { PageSortOrder } from '@/lib/api/pagination.page.dto';

interface LocationBalanceDrawerDrawerProps {
  locationId: number;
  isOpen: boolean;
  onClose: () => void;
  displayMode: DisplayMode;
}

const LocationBalanceDrawerDrawer: FunctionComponent<LocationBalanceDrawerDrawerProps> = ({ locationId, isOpen, onClose, displayMode }) => {
  const [periodInDays, setPeriodInDays] = useState(30);

  const { data, fetchData, isLoading, setApiCallArg } = useApiCall(() => locationService.getById(locationId));

  const {
    data: assetTotalData,
    fetchData: fetchAssetTotalData,
    isLoading: isAssetTotalDataLoading,
    setApiCallArg: setAssetTotalsApiCallArg,
    isError: isAssetTotalDataError,
  } = useApiCall(() => locationService.getBalance({ locationId }));

  const {
    data: balanceOverTimeData,
    fetchData: fetchBalanceOverTimeData,
    isLoading: isLoadingBalanceOverTimeData,
    setApiCallArg: setBalanceOverTimeApiCallArg,
  } = useApiCall(() =>
    balanceService.getLocationBalanceOverTime({
      locationId: locationId,
      periodInDays: periodInDays,
      includeHierarchical: displayMode === DisplayMode.HIERARCHICAL,
    }),
  );

  const {
    data: assetData,
    fetchData: fetchAssetData,
    isLoading: isAssetDataLoading,
    setApiCallArg: setAssetDataApiCallArg,
  } = useApiCall(() => {
    if (displayMode === DisplayMode.HIERARCHICAL) {
      return locationService.getAssetsHierarchical(
        { locationId: locationId },
        {
          page: 1,
          limit: 1000,
          order: PageSortOrder.DESC,
          sort: AssetSortOption.ASSET_CODE,
        },
      );
    } else {
      return locationService.getAssets(
        { locationId: locationId },
        {
          page: 1,
          limit: 1000,
          order: PageSortOrder.DESC,
          sort: AssetSortOption.ASSET_CODE,
        },
      );
    }
  });

  useEffect(() => {
    if (displayMode === DisplayMode.HIERARCHICAL) {
      setAssetDataApiCallArg(() =>
        locationService.getAssetsHierarchical(
          { locationId },
          {
            page: 1,
            limit: 1000,
            order: PageSortOrder.DESC,
            sort: AssetSortOption.ASSET_CODE,
          },
        ),
      );
    } else {
      setAssetDataApiCallArg(() =>
        locationService.getAssets(
          { locationId },
          {
            page: 1,
            limit: 1000,
            order: PageSortOrder.DESC,
            sort: AssetSortOption.ASSET_CODE,
          },
        ),
      );
    }
    setApiCallArg(() => locationService.getById(locationId));
    setAssetTotalsApiCallArg(() => locationService.getBalance({ locationId }));
  }, [locationId, displayMode]);

  useEffect(() => {
    setBalanceOverTimeApiCallArg(() =>
      balanceService.getLocationBalanceOverTime({
        locationId: locationId,
        periodInDays: periodInDays,
        includeHierarchical: displayMode === DisplayMode.HIERARCHICAL,
      }),
    );
  }, [periodInDays, locationId, displayMode]);

  const [value, setValue] = useState('1');

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const onAssetsMoved = () => {
    fetchAssetData();
    fetchAssetTotalData();
    fetchBalanceOverTimeData();
  };

  return (
    <Portal>
      <Drawer anchor={'right'} open={isOpen} hideBackdrop variant="persistent">
        <div className="flex h-full  w-[768px] flex-col  justify-between  px-6 py-4">
          <div className="flex h-full  flex-col ">
            {!data || isLoading ? (
              <LoadingBlock />
            ) : (
              <>
                {/* header */}
                <div className="div flex items-center justify-between">
                  <div className="flex flex-col">
                    <div className="text-xs text-gray-500 dark:text-gray-400 ">Location</div>
                    <TextLink target="_blank" to={{ pathname: '/app/locations/' + data.dto.id }}>
                      <div className="flex items-center">
                        <div className="text-lg font-medium ">
                          {data.dto.name} ({data.dto.code})
                        </div>
                        <div className="">
                          <IosShareRounded className="ml-1 " color="inherit" fontSize="inherit" />
                        </div>
                      </div>
                    </TextLink>
                  </div>
                  <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                    <Close />
                  </IconButton>
                </div>
                {/* content */}
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                      <Tab label="Balance" value="1" />
                      <Tab label={isAssetDataLoading ? 'Assets' : `Assets (${assetData?.totalElements})`} value="2" />
                    </TabList>
                  </Box>
                  <TabPanel className="p-0" value="1" style={{ flexGrow: 1 }}>
                    <div className="flex  h-full flex-col ">
                      <div className="mb-2 mt-4 flex flex-col flex-1 max-h-96">
                        {assetTotalData !== undefined && (
                          <LocationBalanceDrawerBalanceGrid
                            data={assetTotalData}
                            isError={isAssetTotalDataError}
                            isLoading={isLoading}
                            balanceDisplayMode={displayMode}
                          ></LocationBalanceDrawerBalanceGrid>
                        )}
                      </div>

                      <div className="mb-2 mt-4 flex flex-col flex-1 max-h-60">
                        <div className="flex justify-between items-center">
                          <div className="mb-1 font-medium">Balance Over Time</div>
                          <div>
                            <PeriodToggleButton
                              onChange={(event, newPeriodIndays) => setPeriodInDays(newPeriodIndays)}
                              periodInDays={periodInDays}
                            />
                          </div>
                        </div>
                        {balanceOverTimeData !== undefined && (
                          <BalanceOverTimeGraph
                            balancesOverTime={balanceOverTimeData}
                            isLoading={isLoadingBalanceOverTimeData}
                            balanceOverTimeType={GroupPerTimeType.DAY}
                            layoutWidth={768}
                            displayLimit={6}
                          ></BalanceOverTimeGraph>
                        )}
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel value="2" style={{ flexGrow: 1 }}>
                    <div className="flex pt-4 h-full flex-col">
                      <AssetsAtLocationGrid
                        data={assetData?.data}
                        isError={false}
                        onAssetsMoved={onAssetsMoved}
                        isLoading={isAssetDataLoading}
                      />
                    </div>
                  </TabPanel>
                </TabContext>
              </>
            )}
          </div>
          <div className="w-full"></div>
        </div>
      </Drawer>
    </Portal>
  );
};

export default LocationBalanceDrawerDrawer;
